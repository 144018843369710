import React from "react";
import { BlogPost } from "../../types/types";
import BlogCard from "./BlogCard";

interface BlogListProps {
  blogPosts: BlogPost[];
}

const BlogList: React.FC<BlogListProps> = ({ blogPosts }) => {
  return (
    <>
      <div className="md:p-5">
        <h2 className="text-2xl md:text-3xl font-bold text-white uppercase mb-8 text-center">
          Recent Articles
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {blogPosts.map((post, index) => (
          <BlogCard key={index} post={post} index={index} />
        ))}
      </div>
      <div className="flex justify-center w-full px-4 mt-10 mb-10">
        <a
          href="https://medium.com/@alanjonesburga"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-red-900 text-white text-sm md:text-lg font-semibold py-3 px-6 md:py-4 md:px-8 rounded-lg shadow-lg hover:bg-red-700 transition duration-300"
          style={{
            width: "fit-content",
          }}
        >
          VIEW MORE ARTICLES
        </a>
      </div>
    </>
  );
};

export default BlogList;
