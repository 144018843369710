import React from "react";
import ReactPlayer from "react-player";

interface MediaItem {
  id: string;
  type: "video" | "playlist"; // Media type: video or playlist
  title: string;
  description?: string;
  url: string; // YouTube URL
}

interface MediaSectionProps {
  mediaItems: MediaItem[];
}

const MediaSection: React.FC<MediaSectionProps> = ({ mediaItems }) => {
  return (
    <section className="p-6 bg-black bg-opacity-60 shadow-lg max-w-full mx-auto border-gray-500 border-y drop-shadow-lg min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-2xl md:text-3xl text-white font-bold mb-4 text-center uppercase mt-5">
          Favorite Media
        </h2>
        <p className="text-white text-lg text-center p-1 mb-4">
          Enjoy these videos and playlists that inspire, uplift, and strengthen
          Christian voices, offering powerful messages of faith, hope, and
          encouragement. These resources are great references for personal
          growth, deepening your relationship with God, theology, discernment,
          and sharing the Gospel with others and especially learning about
          Christian Apologetics which is something that we are deepyly
          passionate about defending the faith.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mediaItems.map((item) => (
            <div
              key={item.id}
              className="shadow-lg rounded-lg overflow-hidden"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="relative">
                <ReactPlayer
                  url={item.url}
                  controls
                  width="100%"
                  height="100%"
                  className="aspect-video"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-white mb-2">
                  {item.title}
                </h3>
                {item.description && (
                  <p className="text-md text-gray-300 mt-2">
                    {item.description}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MediaSection;
