export const FavoriteBibleVerseRight = [
  {
    reference: "Matthew 5:44 (KJV)",
    verse: `"But I say unto you, Love your enemies, bless them that curse you, do good to them that hate you, and pray for them which despitefully use you, and persecute you."`,
  },
  {
    reference: "2 Corinthians 5:7 (KJV)",
    verse: `"For we walk by faith, not by sight."`,
  },
  {
    reference: "Ecclesiastes 10:12 (KJV)",
    verse: `"The words of a wise man’s mouth are gracious; but the lips of a fool will swallow up himself."`,
  },
  {
    reference: "Matthew 5:22 (KJV)",
    verse: `"But I say unto you, That whosoever is angry with his brother without a cause shall be in danger of the judgment."`,
  },
  // {
  //    reference: "James 4:7 (KJV)",
  //    verse: `"Submit yourselves therefore to God. Resist the devil, and he will flee from you."`,
  //  },
  // {
  //   reference: "Philippians 2:3 (KJV)",
  //   verse: `"Do nothing out of selfish ambition or vain conceit. Rather, in humility value others above yourselves."`,
  // },
  // {
  //   reference: "Colossians 3:12 (KJV)",
  //   verse: `"Set your affections on things above, not on things of the earth."`,
  // },
  // {
  //   reference: "Philippians 3:13-14 (NIV)",
  //   verse: `"Brothers and sisters, I do not consider myself yet to have taken hold of it. But one thing I do: Forgetting what is behind and straining toward what is ahead, I press on toward the goal to win the prize for which God has called me heavenward in Christ Jesus."`,
  // },
  // {
  //   reference: "Ecclesiastes 4:9-10 (NIV)",
  //   verse: `"Two are better than one, because they have a good return for their labor: If either of them falls down, one can help the other up. But pity anyone who falls and has no one to help them up."`,
  // },
  // {
  //   reference: "Proverbs 3:5-6 (NIV)",
  //   verse: `"Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight."`,
  // },
  // {
  //   reference: "John 1:9(NIV)",
  //   verse: `"If we confess our sins, he is faithful and just and will forgive us our sins and purify us from all unrighteousness."`,
  // },
];
