export const BibleStudyPdfs = [
  {
    title: "Life Apllication Study Bible Preview - John, Philippians",
    thumbnail: "/thumbnails/lifeappbible.png",
    pdf: "https://www.paperturn-view.com/tyndale-bibles/nlt-life-application-study-bible-third-edition?pid=NTI52992&v=1.34",
    author: "Tyndale House Publishers",
  },
  {
    title: "NLT Study Bible",
    thumbnail: "/thumbnails/nlt-study-bible.png",
    pdf: "https://docs.google.com/viewer?srcid=1VzTPMAZ79jauKXHViC-4-aSDHgfihQ3l&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Tyndale House Publishers",
  },
  {
    title: "Atlas of the Bible",
    thumbnail: "/thumbnails/atlas-of-the-bible.png",
    pdf: "https://docs.google.com/viewer?srcid=1U7YeEn8kNc8SKdGcjkZTtRN7uzCcYp6O&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Readers Digest",
  },
  {
    title: "NKJV Study Bible",
    thumbnail: "/thumbnails/nkjv-study-bible.png",
    pdf: "https://archive.org/embed/nkjvstudybible",
    author: "Thomas Nelson",
  },
  {
    title: "Scientific Facts of the Bible",
    thumbnail: "/thumbnails/scientific-facts.png",
    pdf: "https://docs.google.com/viewer?srcid=1rGEvNfAdM-QDdb_fJv5e1ae2TX97C1OE&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Ray Comfort",
  },
  {
    title: "Zondervan Illustrated Bible Dictionary",
    thumbnail: "/thumbnails/zondervan.png",
    pdf: "https://archive.org/embed/zondervan-illustrated-bible-dictionary_202210",
    author: "J.D. Douglas and Merrill C. Tenney",
  },
  {
    title: "NKJV Study Bible: New King James Version Study Bible",
    thumbnail: "/thumbnails/NKJBibleStudy.png",
    pdf: "https://archive.org/details/nkjvstudybible/mode/2up",
    author:
      "Radmacher, Earl D; Allen, Ronald Barclay; House, H. Wayne; Thomas Nelson Publishers",
  },
  {
    title: "The Case For Christ",
    thumbnail: "/thumbnails/case-for-christ.png",
    pdf: "https://archive.org/embed/caseforchristbyl0000lees",
    author: "Zondervan (1998-01-01)",
  },
  {
    title: "The New Answers Book 1",
    thumbnail: "/thumbnails/answers-book.png",
    pdf: "https://archive.org/embed/ken-ham-the-new-answers-book-1",
    author: "Ken Ham",
  },
  {
    title: "The New Answers Book 2",
    thumbnail: "/thumbnails/answers-book-2.png",
    pdf: "https://archive.org/embed/ken-ham-the-new-answers-book-2",
    author: "Ken Ham",
  },
  {
    title: "The New Answers Book 3",
    thumbnail: "/thumbnails/answers-book-3.png",
    pdf: "https://archive.org/embed/ken-ham-the-new-answers-book-3_202301",
    author: "Ken Ham",
  },
  {
    title: "I Don't Have Enough Faith to Be an Atheist",
    thumbnail: "/thumbnails/faith-to-be-atheist.png",
    pdf: "https://archive.org/embed/norman-l.-geisler-frank-turek-david-limbaugh-i-dont-have-enough-faith-to-be-an-a",
    author: "Norman L. Geisler, Frank Turek, David Limbaugh",
  },
  {
    title: "A brief Course on Apologetics",
    thumbnail: "/thumbnails/university-potsdam-apologetics.png",
    pdf: "https://docs.google.com/viewer?srcid=1GOR3jrspjcl-qLYbek6wLqv3nREJgIaJ&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
    author: "Cristoph Kreitz - University of Potsdam",
  },
];
