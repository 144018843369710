import React from "react";
import natureBackground from "../assets/nature.jpg";
import { FavoriteBibleVerseLeft } from "../constants/FavoriteBibleVerseLeft";
import { FavoriteBibleVerseRight } from "../constants/FavoritBibleVerseRight";

const BibleVerses: React.FC = () => {
  return (
    <>
      <div
        className="relative min-h-screen bg-cover bg-center bg-opacity-80 flex items-center justify-center px-6 py-10"
        style={{
          backgroundImage: `url(${natureBackground})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPositionX: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40 border border-radius border-white"></div>

        <div className="relative max-w-7xl w-full text-white grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl mb-5 font-bold text-white uppercase">
              Favorite Bible Verses:
            </h2>
            {FavoriteBibleVerseLeft.map((verse, index) => (
              <div key={index} className="mb-6">
                <h3 className="font-semibold text-lg md:text-xl">
                  {verse.reference}
                </h3>
                <p className="italic text-lg md:text-xl">{verse.verse}</p>
              </div>
            ))}
          </div>
          <div>
            {FavoriteBibleVerseRight.map((verse, index) => (
              <div key={index} className="mb-6">
                <h3 className="font-semibold text-lg md:text-xl">
                  {verse.reference}
                </h3>
                <p className="italic text-lg md:text-xl">{verse.verse}</p>
              </div>
            ))}

             {/* CTA Button */}
      <div className="text-center">
        <a
          href="https://medium.com/@alanjonesburga/finding-strength-and-peace-in-lifes-challenges-lessons-from-bible-verses-1fed68084ec9"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-blue-600 text-white text-sm md:text-lg font-semibold py-3 px-6 md:py-4 md:px-8 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 m-6"
          style={{
            width: "fit-content",
          }}
        >
          View More Bible Verses
        </a>
      </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default BibleVerses;
