import React from "react";
import { FaLinkedin, FaGithub, FaEnvelope, FaGlobe } from "react-icons/fa";
import dashboardApp from "../../assets/dashboardapp.png";

export default function FuturePlans() {
  return (
    <div className="relative bg-black bg-opacity-50 p-6 shadow-lg rounded-lg max-w-md">
      {/* Future Plans Section */}
      <div className="relative z-10">
        <div className="text-sm font-bold mb-2 uppercase">
          Now Available Beta Version:{" "}
          <p className="text-blue-400 text-sm uppercase">
            Living For Jesus Christ Dashboard
          </p>
        </div>
        <p className="text-left text-sm text-white mb-4">
          Take a look at our brand new accompanying Dashboard application
          (curently on Beta) that will help you track your spiritual growth and
          development. This application is designed to help you keep track of
          your Bible study progress, spiritual goals, and personal space for 
          all things related to your walk with Jesus Christ.
        </p>
        <a
          href="https://liveforjesuschrist.tech"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="overflow-hidden">
            <img
              src={dashboardApp}
              alt="Living For Jesus Christ Dashboard App"
              className="w-full h-auto rounded-lg shadow-lg mb-4 transform transition-transform duration-300 hover:scale-110"
            />
          </div>
        </a>

        <p className="text-left text-sm text-blue-400 mb-4">
          <a
            href="https://liveforjesuschrist.tech"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            https://liveforjesuschrist.tech/
          </a>
        </p>
      </div>

      {/* Interested in Hiring Section */}
      {/* <div className="relative z-10 mt-6">
        <h4 className="text-lg font-bold text-yellow-400 mb-2">
          Software Engineering / Web Development Services:
        </h4>
        <ul className="text-left text-white text-sm space-y-3">
          <li className="flex items-center space-x-2">
            <FaGlobe className="text-blue-400" />
            <a
              href="https://codejones.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              Portfolio
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaLinkedin className="text-blue-600" />
            <a
              href="https://www.linkedin.com/in/alanjones83/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              LinkedIn
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaGithub className="text-gray-300" />
            <a
              href="https://github.com/ajonesb"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 transition"
            >
              GitHub
            </a>
          </li>
          <li className="flex items-center space-x-2">
            <FaEnvelope className="text-red-400" />
            <a
              href="mailto: alan.jones170183@gmail.com"
              className="hover:text-blue-400 transition"
            >
              alan.jones170183@gmail.com
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
