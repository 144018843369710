import React, { useState } from "react";
import Loader from "./Loader";

interface FlipbookViewerProps {
  pdfUrl: string;
  author: string; // Add author as a prop
  onClose: () => void;
}

const FlipbookViewer: React.FC<FlipbookViewerProps> = ({
  pdfUrl,
  author,
  onClose,
}) => {
  const [iframeLoading, setIframeLoading] = useState(true);

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      {iframeLoading && <Loader />}
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1000,
          padding: "0.5rem 1rem",
          background: "red",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Close Viewer
      </button>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: 1000,
          padding: "0.5rem 1rem",
          background: "black",
          color: "white",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0 }}>Author: {author}</p>
      </div>
      <iframe
        src={pdfUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="PDF Viewer"
        onLoad={() => setIframeLoading(false)}
      ></iframe>
    </div>
  );
};

export default FlipbookViewer;
