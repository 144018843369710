import React from "react";
import CarratereSVG from "./FontGraphics/Carrratere";

const HomeIntro: React.FC = () => {
  return (
    <div className="p-5 md:p-10 min-h-auto bg-black bg-center bg-opacity-20 border border-radius border-white">
      <div className="max-w-[400px] mb-5">
        <CarratereSVG />
      </div>
      {/* <h2 className="text-2xl md:text-2xl font-bold text-white uppercase text-left mb-5">
        Living For and Through Jesus Christ Everyday
      </h2> */}
      <p className="text-left text-white text-md md:text-2xl">
        Thank you for visiting our site. We are Alan Jones and Maria Luisa
        Jones, born-again Christians wholeheartedly devoted to living for and
        through our Lord and Savior, Jesus Christ. Guided daily by the Holy
        Spirit, we strive to live fully in His grace and truth. <br />
        <br />
        As a loving husband and wife, and children of our great Lord Jesus
        Christ, we created this site to share valuable resources for fellow
        Christians and anyone seeking to navigate life’s challenges with faith.
        <br />
        <br />
        Our mission is to provide tools and guidance to help others live
        empowered by the Holy Spirit. This site is a non-profit endeavor, built
        from the ground up by ourselves. We hope these resources and tools will
        inspire and strengthen your walk with Christ, just as they have
        profoundly transformed our own lives. Our prayer is that this platform
        serves as a dependable reference for seeking the wisdom of the Lord
        whenever you need it. We have found some great resources that have
        helped us grow in our faith and we hope they help you too. <br />
      </p>

      {/* <h2 className="text-2xl md:text-2xl font-bold text-white uppercase text-left mt-10 mb-5">
        Why we turned our lives over to Jesus
      </h2>
      <p className="text-left text-white text-lg md:text-2xl">
        We grew up with the typical Catholic culture as many Latin families do.
        We have always had the Lord in our hearts, but we never truly understood
        the importance of having a personal relationship with Him until now when
        we fully turned over our lives to Him. <br />
        <br />
        We always felt there was something missing with the Catholic upbringing
        and never understood the sacraments you have to achieve in order to get
        the Lord to accept you according to this Catholic system and culture
        additionally as well as did not agree with a lot of this man made system
        explained in detail{" "}
        <a
          href="https://medium.com/@alanjonesburga/why-our-faith-should-be-centered-solely-on-jesus-christ-as-christians-and-avoid-religions-new-4547eecd052b"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white underline"
        >
          here.
        </a>
        <br />
        <h2 className="text-2xl md:text-2xl font-bold text-white uppercase text-left mt-10 mb-5">
          How I found Jesus Christ and turned my life over to Him
        </h2>
        I have always felt a deep connection with God and Jesus Christ. Growing
        up in the U.S., where Catholicism isn’t as prevalent as in Latin
        America, I found many traditional practices unnecessary. Since
        childhood, when my mother taught me to pray the Lord’s Prayer, I’ve felt
        God’s protection through daily prayer and simple believing in him was
        enough. <br />
        <br />
        Now, at 42, I know I was born to be a Christian. I believe God’s love
        doesn’t require rituals or sacraments—just faith. After all I’ve
        experienced, I realize my life is meant to be lived for and through Him.
        This change has been transformative. I have no time to waist but live
        my life for Him. <br />
        <br />
        
        I’ve been through periods of atheism and agnosticism, feeling lost in
        complete darkness in my past. It was only when I finally completely
        turned back to God, got rid of that old man consumed by the enemy with
        inner demons and constant attacks, seeking forgiveness and guidance,
        that He worked wonders for me and my family. Even when I abandoned Him,
        He never abandoned me. <br />
        <br />
        God’s love is forgiving and boundless. We are His children, and through
        Jesus Christ’s ultimate sacrifice, we owe Him everything. Our lives
        should reflect gratitude and devotion to His glory, for the blood he 
        shed for us on the cross and not live in the world which only leads to 
        destruction. <br />
      </p> */}
    </div>
  );
};

export default HomeIntro;
